import React, { useState, useEffect } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import './App.css';

function App() {
  const [apiStatus, setApiStatus] = useState([]);
  const [cpuData, setCpuData] = useState([]);
  const [memoryData, setMemoryData] = useState([]);
  const [diskUsageData, setDiskUsageData] = useState([]);
  const [networkTransmitData, setNetworkTransmitData] = useState([]);
  const [networkReceiveData, setNetworkReceiveData] = useState([]);

  const fetchStatus = async () => {
    try {
      const response = await fetch('https://cf-worker-status-page.localdomain.workers.dev');
      const data = await response.json();
      console.log('Fetched API data:', data); // Log the fetched data
      setApiStatus(data.statuses);
      setCpuData(data.cpuValues); // Assuming cpuValues is an array of CPU values
      setMemoryData(data.memoryValues); // Assuming memoryValues is an array of memory values
      setDiskUsageData(data.diskUsageValues); // Assuming diskUsageValues is an array of disk usage values
      setNetworkTransmitData(data.networkTransmitValues); // Assuming networkTransmitValues is an array of network transmit values
      setNetworkReceiveData(data.networkReceiveValues); // Assuming networkReceiveValues is an array of network receive values
    } catch (error) {
      console.error('Error fetching API status:', error);
    }
  };

  useEffect(() => {
    fetchStatus(); // Fetch status immediately on component mount
    const intervalId = setInterval(fetchStatus, 60000); // Fetch status every 60 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  // Generate timestamps for each value
  const generateTimestamps = (data) => {
    return data.map((_, index) => {
      const now = new Date();
      return new Date(now.getTime() - (data.length - 1 - index) * 60000).toLocaleTimeString();
    });
  };

  const chartOptions = {
    scales: {
      x: {
        display: false, // Hide x-axis labels
      },
    },
  };

  const cpuChartData = {
    labels: generateTimestamps(cpuData),
    datasets: [
      {
        label: 'CPU Usage (%)',
        data: cpuData,
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
      },
    ],
  };

  const memoryChartData = {
    labels: generateTimestamps(memoryData),
    datasets: [
      {
        label: 'Memory Usage (%)',
        data: memoryData,
        fill: false,
        backgroundColor: 'rgba(153,102,255,0.2)',
        borderColor: 'rgba(153,102,255,1)',
      },
    ],
  };

  const diskUsageChartData = {
    labels: generateTimestamps(diskUsageData),
    datasets: [
      {
        label: 'Disk Usage (%)',
        data: diskUsageData,
        fill: false,
        backgroundColor: 'rgba(255,159,64,0.2)',
        borderColor: 'rgba(255,159,64,1)',
      },
    ],
  };

  const networkChartData = {
    labels: generateTimestamps(networkTransmitData),
    datasets: [
      {
        label: 'Network Transmit (KB/s)',
        data: networkTransmitData,
        fill: false,
        backgroundColor: 'rgba(54,162,235,0.2)',
        borderColor: 'rgba(54,162,235,1)',
      },
      {
        label: 'Network Receive (KB/s)',
        data: networkReceiveData,
        fill: false,
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
      },
    ],
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>danslinky ☁️ Status Page</h1>
      </header>
      <p>Last updated: 1 minute ago</p>
      <div className="status-list">
        {apiStatus.map((api, index) => (
          <div key={index} className="status-item">
            <span className="status-name">
              <a href={api.url} target="_blank" rel="noopener noreferrer">
                {api.name}
              </a>
            </span>
            <span className="status-emoji">
              {api.status === 'ok' ? '✅' : '❌'}
            </span>
          </div>
        ))}
      </div>
      <div className="chart-grid">
        <div className="chart-container">
          <h2>CPU Usage</h2>
          <Line data={cpuChartData} options={chartOptions} />
        </div>
        <div className="chart-container">
          <h2>Memory Usage</h2>
          <Line data={memoryChartData} options={chartOptions} />
        </div>
        <div className="chart-container">
          <h2>Disk Usage</h2>
          <Line data={diskUsageChartData} options={chartOptions} />
        </div>
        <div className="chart-container">
          <h2>Network Usage</h2>
          <Line data={networkChartData} options={chartOptions} />
        </div>
      </div>
    </div>
  );
}

export default App;